import { z } from "zod"
import { printZodValidationError } from "@hornet-web-react/core/utils/print-zod-validation-error"

export const AdhocCampaignId = z.coerce.string().brand("AdhocCampaignId")
export type AdhocCampaignId = z.infer<typeof AdhocCampaignId>

const AdhocCampaignText = z.object({
  text: z.string(),
  text_color: z.string(),
})

export const AdhocCampaignApiPayload = z.object({
  adhoc_campaign: z.object({
    id: AdhocCampaignId,
    analytics: z.record(z.string().or(z.number()).nullable()).optional(),
    layout: z
      .literal("header_image_with_text")
      .or(z.literal("full_screen_image")),
    image: z.object({
      url: z.string().url(),
      action: z.string().nullable(),
    }),
    heading_1: AdhocCampaignText,
    heading_2: AdhocCampaignText,
    heading_3: AdhocCampaignText.nullable(),
    button: AdhocCampaignText.extend({
      background_color: z.string(),
      action: z.string(),
    }).optional(),
    bottom_text: AdhocCampaignText.nullable(),
    background_color: z.string(),
    postback: z
      .object({
        url: z.string().url(),
        params: z.record(z.coerce.string()),
      })
      .optional(),
  }),
})
export type AdhocCampaignApiPayload = z.infer<typeof AdhocCampaignApiPayload>

export function createAdhocCampaignModel(payload?: AdhocCampaignApiPayload) {
  const model = AdhocCampaignApiPayload.safeParse(payload)
  if (model.success) {
    return new AdhocCampaignModel(model.data)
  }

  payload && printZodValidationError(model.error)
  return
}

export class AdhocCampaignModel {
  private readonly adhocCampaign: AdhocCampaignApiPayload["adhoc_campaign"]

  constructor(payload: AdhocCampaignApiPayload) {
    this.adhocCampaign = payload["adhoc_campaign"]
  }

  get id() {
    return this.adhocCampaign.id
  }

  get analytics(): Record<string, string> {
    if (!this.adhocCampaign.analytics) {
      return {}
    }

    const nonNullValues = Object.entries(this.adhocCampaign.analytics)
      .filter(([_, value]) => value !== null && value !== "")
      .map(([key, value]) => [key, String(value)])

    return Object.fromEntries(nonNullValues)
  }

  get layout() {
    return this.adhocCampaign.layout
  }

  get image() {
    return this.adhocCampaign.image
  }

  get heading1() {
    return this.adhocCampaign.heading_1
  }

  get heading2() {
    return this.adhocCampaign.heading_2
  }

  get heading3() {
    return this.adhocCampaign.heading_3
  }

  get button() {
    return this.adhocCampaign.button
  }

  get bottomText() {
    return this.adhocCampaign.bottom_text
  }

  get backgroundColor() {
    return this.adhocCampaign.background_color
  }

  get postbackUrl() {
    return this.adhocCampaign.postback?.url
  }
}
