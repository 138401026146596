import NiceModal from "@ebay/nice-modal-react"
import InfoSheetModal from "./InfoSheetModal"
import styled from "styled-components"
import { useEventTrackerService } from "../../hooks/use-event-tracker-service"
import { AdhocCampaignId } from "@hornet-web-react/core/models/adhoc-campaign.model"
import { useAdhocCampaign } from "@hornet-web-react/core/hooks/use-adhoc-campaign"
import Spinner from "@hornet-web-react/core/components/UI/Spinner"
import { useEffect, useRef } from "react"
import { useLoggerService } from "@hornet-web-react/core/hooks/use-logger-service"
import CloseModalButtonV2 from "@hornet-web-react/core/components/Modals/CloseModalButtonV2"
import QuickiesAnalyticsEvent from "../../models/quickies-analytics-event"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import { TYPES as CORE_TYPES } from "@hornet-web-react/core/services/types"
import { ApiService } from "@hornet-web-react/core/services/API"
import { useSessionDevice } from "@hornet-web-react/core/contexts/session"
import { useRouter } from "next/router"
import { useLazyLoaded } from "@hornet-web-react/core/hooks/use-lazy-loaded"

// const i18nKey = "quickies:components.modals.adhoc_campaign_modal"

type AdhocCampaignModalProps = {
  adhocCampaignId: AdhocCampaignId
}

const AdhocCampaignModal = NiceModal.create(
  ({ adhocCampaignId }: AdhocCampaignModalProps) => {
    // const { t } = useTranslation()
    const { data, isLoading, error } = useAdhocCampaign(adhocCampaignId)
    const { reportEvent } = useEventTrackerService()
    const apiService = useCoreService<ApiService>(CORE_TYPES.ApiService)
    const { logExceptionWithSentry, createLoggingContext } = useLoggerService()
    const hasReported = useRef<AdhocCampaignId[]>([])
    const modal = NiceModal.useModal()
    const { localeRoute } = useSessionDevice()
    const router = useRouter()
    const { get: getDickVerificationModal } = useLazyLoaded(
      () => import("./DickVerificationModal")
    )
    const { get: getEditQuickiesProfileModal } = useLazyLoaded(
      () => import("../Modals/EditQuickiesProfileModal")
    )

    useEffect(() => {
      if (error && !hasReported.current.includes(adhocCampaignId)) {
        hasReported.current.push(adhocCampaignId)
        logExceptionWithSentry(
          error,
          createLoggingContext({
            component: "BlastPopupModal",
          })
        )

        void modal.hide()
      }
    }, [
      createLoggingContext,
      error,
      logExceptionWithSentry,
      modal,
      adhocCampaignId,
    ])

    useEffect(() => {
      if (data?.id && !hasReported.current.includes(data.id)) {
        hasReported.current.push(data.id)

        void reportEvent(
          QuickiesAnalyticsEvent.blastPopupModalViewed(data.id, data.analytics)
        )

        if (data.postbackUrl) {
          void apiService.post(data.postbackUrl, {}).catch((error) => {
            logExceptionWithSentry(
              error,
              createLoggingContext({
                component: "BlastPopupModal",
                step: "postback",
              })
            )
          })
        }
      }
    }, [
      apiService,
      createLoggingContext,
      data,
      logExceptionWithSentry,
      reportEvent,
    ])

    function parseCtaAction(action?: string): [boolean, () => void] {
      if (!action || action === "hrnt://noop") {
        return [
          false,
          () => {
            //
          },
        ]
      }

      if (action.startsWith("https://")) {
        return [
          true,
          () => {
            window.open(encodeURI(action), "_blank")
          },
        ]
      }

      switch (action) {
        case "hrnt://map":
        case "hrnt://account":
        case "hrnt://inbox/all":
        case "hrnt://inbox/groups":
        case "hrnt://groups":
          // void on purpose, we don't want to wait with closing the modal until the
          // router change is complete (aka "close and navigate")
          return [
            true,
            () => {
              void router.push(
                `${localeRoute}/${action.replace("hrnt://", "")}`
              )
            },
          ]

        case "hrnt://verify-my-dick":
          return [
            true,
            () => getDickVerificationModal((modal) => NiceModal.show(modal)),
          ]
        case "hrnt://edit-my-profile":
          return [
            true,
            () => getEditQuickiesProfileModal((modal) => NiceModal.show(modal)),
          ]
      }

      return [
        false,
        () => {
          //
        },
      ]
    }

    const [hasAction, handleAction] = parseCtaAction(data?.button?.action)

    return (
      <InfoSheetModal modalName={"BlastPopupModal"}>
        {({ modal, closeAction }) => (
          <>
            <CloseModalButtonV2 onClick={closeAction.onClick} />

            {isLoading || !data ? (
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
            ) : (
              <>
                <InfoSheetModal.Heading>
                  {data.heading1.text}
                </InfoSheetModal.Heading>

                <InfoSheetModal.Paragraph>
                  {data.heading2.text}
                </InfoSheetModal.Paragraph>

                {data.image.url ? (
                  <ImageWrapper>
                    <CampaignImage src={data.image.url} alt={""} />
                  </ImageWrapper>
                ) : null}

                {data?.button?.text && hasAction ? (
                  <InfoSheetModal.SubmitButton
                    primary={true}
                    onClick={async () => {
                      if (data.button?.action) {
                        void handleAction()
                      }

                      void reportEvent(
                        QuickiesAnalyticsEvent.blastPopupModalTapOnCta(
                          data.id,
                          data.analytics
                        )
                      )
                      modal.hide()
                    }}
                  >
                    {data.button.text}
                  </InfoSheetModal.SubmitButton>
                ) : null}
              </>
            )}
          </>
        )}
      </InfoSheetModal>
    )
  }
)

export default AdhocCampaignModal

const ImageWrapper = styled.div`
  width: 100%;
  aspect-ratio: 13 / 9;
  margin: 0 auto ${({ theme }) => theme.spacing.double};
  border-radius: 4px;
  position: relative;
`

const CampaignImage = styled.img`
  display: block;
  width: 100%;
`

const SpinnerWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing.double};
`
