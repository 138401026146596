import { FC, MouseEvent } from "react"
import styled from "styled-components"
import {
  ButtonStyle,
  smallSizeStyle,
} from "@hornet-web-react/core/components/UI/TextButton"
import VerifiedDickIcon from "@hornet-web-react/core/components/Icons/VerifiedDickIcon"
import NiceModal from "@ebay/nice-modal-react"
import useTranslation from "next-translate/useTranslation"
import { useQuickiesPaywall } from "../../hooks/use-quickies-paywall"
import { useQuickiesPremium } from "../../hooks/use-quickies-premium"
import { QuickiesPremiumPaywall } from "@hornet-web-react/core/types"
import { useLazyLoaded } from "@hornet-web-react/core/hooks/use-lazy-loaded"
import { useEventTrackerService } from "../../hooks/use-event-tracker-service"
import QuickiesAnalyticsEvent from "../../models/quickies-analytics-event"

type VerifyDickButtonProps = {
  isLongButton?: boolean
  isPending?: boolean
}

const i18nKey = "quickies:hooks.use_dick_verification"

const VerifyDickButton: FC<VerifyDickButtonProps> = ({
  isLongButton = false,
  isPending = false,
}) => {
  const { t } = useTranslation()
  const { isDickVerificationPaywalled } = useQuickiesPaywall()
  const { openQuickiesPremiumModal } = useQuickiesPremium()
  const { get: getDickVerificationModal } = useLazyLoaded(
    () => import("../Modals/DickVerificationModal")
  )
  const { reportEvent } = useEventTrackerService()

  const handleOnClick = async (event: MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()

    void reportEvent(QuickiesAnalyticsEvent.myProfileTapOnVerifyDick())

    if (isDickVerificationPaywalled) {
      void openQuickiesPremiumModal(
        QuickiesPremiumPaywall.enum.dick_verification
      )
      return
    }

    void getDickVerificationModal((modal) => NiceModal.show(modal))
  }

  if (isPending) {
    return (
      <PendingButton>
        <VerifiedDickIcon width={18} height={18} color={"#B7B6BB"} />
        {t(`${i18nKey}.pending`)}
      </PendingButton>
    )
  }

  if (isLongButton) {
    return (
      <LongButton onClick={handleOnClick}>
        <VerifiedDickIcon width={18} height={18} />
        {t(`${i18nKey}.verify_long`)}
      </LongButton>
    )
  }

  return (
    <Button onClick={handleOnClick}>
      <VerifiedDickIcon width={18} height={18} />
      {t(`${i18nKey}.verify_short`)}
    </Button>
  )
}

export default VerifyDickButton

const Button = styled.button`
  ${ButtonStyle};
  ${smallSizeStyle};
  min-width: fit-content;
  flex-direction: row;
  background: #232323;
  gap: 4px;
  padding-left: 12px;
  padding-right: 12px;
`

const PendingButton = styled.button`
  ${ButtonStyle};
  ${smallSizeStyle};
  min-width: fit-content;
  flex-direction: row;
  background: #232323;
  gap: 4px;
  padding-left: 12px;
  padding-right: 12px;
  cursor: default;
  pointer-events: none;
`

const LongButton = styled.button`
  ${ButtonStyle};
  min-width: fit-content;
  width: 100%;
  flex-direction: row;
  background: #232323;
  gap: 4px;
  padding-left: 12px;
  padding-right: 12px;
`
