import { useCallback, useRef } from "react"
import { debug } from "@hornet-web-react/core/utils"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import WebsocketService, {
  MessageListenerCallback,
} from "@hornet-web-react/core/services/WebsocketService"
import { CORE_TYPES } from "@hornet-web-react/core/services/types"
import { z } from "zod"

const QuickiesRealtimeOpenScreenApiPayload = z.object({
  data: z.object({
    hook: z.string(),
  }),
})
type QuickiesRealtimeOpenScreenApiPayload = z.infer<
  typeof QuickiesRealtimeOpenScreenApiPayload
>

export const useQuickiesRealtimeOpenScreen = (
  handler: (deeplinksList: string[]) => void
) => {
  const websocketService = useCoreService<WebsocketService>(
    CORE_TYPES.WebsocketService
  )

  const quickiesRealtimeOpenScreenReceivedListener = (
    payload: QuickiesRealtimeOpenScreenApiPayload
  ) => {
    const messagePayload = QuickiesRealtimeOpenScreenApiPayload.parse(payload)

    handler([messagePayload.data.hook])
  }

  const handleOpenScreenReceived = useRef<
    MessageListenerCallback<QuickiesRealtimeOpenScreenApiPayload>
  >(quickiesRealtimeOpenScreenReceivedListener)
  handleOpenScreenReceived.current = quickiesRealtimeOpenScreenReceivedListener

  return {
    subscribe: useCallback(() => {
      debug("useQuickiesRealtimeOpenScreen.subscribe")

      websocketService.addMessageListener<QuickiesRealtimeOpenScreenApiPayload>(
        "quickiesRealtimeOpenScreen:handleOpenScreenReceived",
        "user/{profileId}/session/open_screen",
        handleOpenScreenReceived
      )
    }, [websocketService]),

    unsubscribe: useCallback(() => {
      debug("useQuickiesRealtimeOpenScreen.unsubscribe")

      websocketService.removeMessageListener(
        "quickiesRealtimeOpenScreen:handleOpenScreenReceived"
      )
    }, [websocketService]),
  }
}
