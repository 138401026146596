var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3.22.0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"
import config from "./src/config"

if (config.sentry.isEnabled) {
  Sentry.init({
    dsn: config.sentry.dsn,
    tracesSampleRate: config.sentry.tracesSampleRate,
    sampleRate: config.sentry.sampleRate,
    debug: config.sentry.debug,
    release: config.sentry.release,
    allowUrls: config.sentry.allowUrls,
    environment: config.environment,
    beforeSend(event, hint) {
      if (!hint || !hint.originalException) {
        return event
      }

      const error = hint.originalException

      if (
        !error ||
        !error.message ||
        typeof error.message.match !== "function"
      ) {
        return event
      }

      // blacklisting errors by keyword
      const blacklistKeywords = [
        "al_onAdViewRendered",
        "al_onPoststitialDismiss",
        "ep1.adtrafficquality.google",
        "kaspersky-labs.com",
        "yandex",
      ]
      const blacklistRegex = new RegExp(blacklistKeywords.join("|"), "gi")

      if (error.message.match(blacklistRegex)) {
        return null
      }

      // ignore recaptcha errors, see:
      // https://github.com/getsentry/sentry-javascript/issues/3440
      // https://github.com/getsentry/sentry-javascript/issues/2514
      // https://github.com/getsentry/sentry-javascript/issues/5833
      if (
        error.message.indexOf(
          "Non-Error promise rejection captured with value: Timeout"
        ) >= 0
      ) {
        return null
      }

      return event
    },
  })
}
