import useImmutableSWR from "swr"
import { useSessionUser } from "@hornet-web-react/core/contexts/session"
import {
  AdhocCampaignApiPayload,
  AdhocCampaignId,
  AdhocCampaignModel,
  createAdhocCampaignModel,
} from "@hornet-web-react/core/models/adhoc-campaign.model"
import { useApi } from "./use-api"
import { ApiServiceEndpoint } from "@hornet-web-react/core/services/API/ApiServiceEndpoint"

type UseAdhocCampaignResult = {
  data: AdhocCampaignModel | undefined
  isLoading: boolean
  error: Error | undefined
}

export function useAdhocCampaign(id: AdhocCampaignId): UseAdhocCampaignResult {
  const { getEndpoint } = useApi()
  const { isAuthenticated } = useSessionUser()

  const { data, error } = useImmutableSWR<AdhocCampaignApiPayload>(
    isAuthenticated
      ? getEndpoint(ApiServiceEndpoint.AdhocCampaignGet, [id])
      : null,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  const model = createAdhocCampaignModel(data)

  return {
    data: model,
    isLoading: !error && !data,
    error: error
      ? error
      : data && !model
      ? new Error("Failed to parse AdhocCampaign data")
      : undefined,
  }
}
