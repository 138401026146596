import { SlideModal } from "@hornet-web-react/core/components/Modals"
import NiceModal from "@ebay/nice-modal-react"
import styled from "styled-components"
import TextButton from "@hornet-web-react/core/components/UI/TextButton"
import { useModal } from "@hornet-web-react/core/hooks/use-modal"
import { ReactNode } from "react"
import type { NavigationAction } from "@hornet-web-react/core/types"

export type InfoSheetModalApi = {
  modal: {
    hide: () => Promise<unknown>
  }
  closeAction: NavigationAction
}

type InfoSheetModalProps = {
  children: (args: InfoSheetModalApi) => ReactNode
  modalName: string
  closeAction?: NavigationAction
  className?: string
  buttonOnClick?: () => void
}

const InfoSheetModal = (props: InfoSheetModalProps) => {
  const modal = NiceModal.useModal()
  const { createAction } = useModal()

  const closeAction =
    props.closeAction ||
    createAction(
      {
        label: " ",
        onClick: () => modal.hide(),
      },
      props.modalName
    )

  return (
    <Modal
      title={""}
      isOpen={modal.visible}
      closeAction={closeAction}
      hasNavigation={false}
      afterClose={() => modal.remove()}
    >
      {props.children({ modal, closeAction })}
    </Modal>
  )
}

const Heading = styled.h2`
  ${({ theme }) => theme.font.bold.title2};
  font-size: 21px; // hack override
  color: ${({ theme }) => theme.color.text.primary};
  margin: ${({ theme }) => theme.spacing.half} 0;
  padding: 14px 28px;
  text-wrap: balance;
  text-align: center;
  min-height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Paragraph = styled.p`
  ${({ theme }) => theme.font.regular.body};
  color: ${({ theme }) => theme.color.text.secondary};
  margin: 0 0 ${({ theme }) => theme.spacing.double} 0;
  text-align: center;
`

const SubmitButton = styled(TextButton)`
  // ${({ theme }) => theme.font.bold.title3};
  width: 100%;
`

const Modal = styled(SlideModal)`
  min-height: 0;
`

const InfoSheetModalWithComponents = Object.assign(InfoSheetModal, {
  Heading,
  Paragraph,
  SubmitButton,
})

export default InfoSheetModalWithComponents
