import { FC, PropsWithChildren, ReactNode, useEffect, useState } from "react"
import TextButton from "@hornet-web-react/core/components/UI/TextButton"
import ImageUpload from "@hornet-web-react/core/components/Form/ImageUpload"
import useTranslation from "next-translate/useTranslation"
import styled from "styled-components"
import InteractiveComponentWrapper from "@hornet-web-react/core/components/Utils/InteractiveComponentWrapper"
import { useEffectOnce } from "usehooks-ts"
import PhotoDataModel from "@hornet-web-react/core/models/photo-data-model"
import {
  ProfilePhotoUploadModel,
  ProfilePhotoUploadResult,
} from "../models/profile-photo-upload.model"
import { SubmitErrorModel } from "@hornet-web-react/core/models/submit-error.model"
import SubmitError from "@hornet-web-react/core/components/UI/SubmitError"
import { isLeft, unwrapEither } from "@hornet-web-react/core/utils"
import invariant from "tiny-invariant"
import NiceModal from "@ebay/nice-modal-react"
import { useLazyLoaded } from "@hornet-web-react/core/hooks/use-lazy-loaded"

type ProfilePhotoUploadProps = PropsWithChildren & {
  className?: string
  onUpload: (
    formData: ProfilePhotoUploadModel
  ) => Promise<ProfilePhotoUploadResult>
  onSelect?: () => void
  customPhotoPolicy?: ReactNode
  photoPlaceholderUrl?: string | null // if null, no placeholder is shown
  addPhotoButtonLabel?: string
  thumbCropTitle?: string
  heroCropTitle?: string
  hasHeroCrop?: boolean
}

const i18nKey = "profile:components.profile_photo_upload"

const ProfilePhotoUpload: FC<ProfilePhotoUploadProps> = ({
  className,
  children,
  onUpload,
  onSelect,
  customPhotoPolicy,
  photoPlaceholderUrl,
  addPhotoButtonLabel,
  thumbCropTitle,
  heroCropTitle,
  hasHeroCrop,
}) => {
  const { t } = useTranslation()
  const { get: getPhotoPolicyModal } = useLazyLoaded(
    () => import("./Modals/PhotoPolicyModal")
  )

  const [isInteractive, setIsInteractive] = useState(false)
  const [isContinueButtonVisible, setIsContinueButtonVisible] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadError, setUploadError] = useState<SubmitErrorModel | null>(null)
  const [formData, setFormData] = useState<ProfilePhotoUploadModel | null>(null)

  useEffectOnce(() => {
    setIsInteractive(true)
  })

  useEffect(() => {
    setIsContinueButtonVisible(
      ProfilePhotoUploadModel.safeParse(formData).success && formData !== null
    )
  }, [formData])

  const placeholderUrl =
    typeof photoPlaceholderUrl === "string"
      ? photoPlaceholderUrl
      : typeof photoPlaceholderUrl === "undefined"
      ? `/assets/images/profile_picture_upload.png`
      : undefined

  const showPhotoPolicyModal = () =>
    getPhotoPolicyModal((modal) => NiceModal.show(modal))

  const setPhotoData = (
    thumbData: PhotoDataModel,
    wideData: PhotoDataModel,
    fileData: File
  ) => {
    setFormData(() => {
      setUploadError(null)

      return {
        file: fileData,
        thumbData: thumbData.serialize(),
        cropData: wideData.serialize(),
      }
    })
  }

  const resetPhotoData = () => {
    setFormData(null)
    setUploadError(null)
  }

  const handleContinue = async () => {
    invariant(formData, "Form data is not set")

    setIsUploading(true)
    setUploadError(null)

    const result = await onUpload(formData)

    // handle errors only, success is handled by parent
    if (isLeft(result)) {
      setUploadError(unwrapEither(result))
      setIsUploading(false)
    }
  }

  return (
    <ImageUpload
      placeholderUrl={placeholderUrl}
      onCtaClick={onSelect}
      onPhotoChange={setPhotoData}
      onPhotoRemove={resetPhotoData}
      heroCropAspectRatio={2 / 3}
      thumbCropTitle={
        thumbCropTitle || t(`${i18nKey}.modal.thumb_crop_heading`)
      }
      hasHeroCrop={hasHeroCrop}
      heroCropTitle={heroCropTitle || t(`${i18nKey}.modal.hero_crop_heading`)}
    >
      {(element, openPictureUpload) => (
        <>
          {uploadError && <SubmitError error={uploadError} />}
          <CenterContainer>
            <PhotoWrapper isInteractive={isInteractive} className={className}>
              <PhotoUploadContainer>{element}</PhotoUploadContainer>
            </PhotoWrapper>
          </CenterContainer>

          <CenterContainer>{children}</CenterContainer>

          <ButtonGroup>
            {!isContinueButtonVisible && (
              <TextButton
                primary={true}
                type={"button"}
                onClick={openPictureUpload}
              >
                {addPhotoButtonLabel || t(`${i18nKey}.fields.add_photo_button`)}
              </TextButton>
            )}

            {isContinueButtonVisible && (
              <TextButton
                primary={true}
                type={"button"}
                onClick={handleContinue}
                disabled={isUploading}
                isLoading={isUploading}
              >
                {t(`${i18nKey}.fields.continue_button`)}
              </TextButton>
            )}
          </ButtonGroup>

          {customPhotoPolicy ? (
            customPhotoPolicy
          ) : (
            <Footer>
              <BottomLine>{t(`${i18nKey}.text.bottom_line`)}</BottomLine>
              <CenterContainer>
                <TextButton
                  link={true}
                  size={"small"}
                  type={"button"}
                  onClick={showPhotoPolicyModal}
                >
                  {t(`${i18nKey}.text.bottom_line_policy`)}
                </TextButton>
              </CenterContainer>
            </Footer>
          )}
        </>
      )}
    </ImageUpload>
  )
}

export default ProfilePhotoUpload

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 12px; // so the Remove Photo button is not cropped
`

const PhotoWrapper = styled(InteractiveComponentWrapper)`
  width: 262px;
  height: 262px;
  position: relative;
`

const PhotoUploadContainer = styled.div`
  width: 260px;
  height: 260px;
`

const BottomLine = styled.p`
  ${({ theme }) => theme.font.regular.caption2};
  color: ${({ theme }) => theme.color.text.tertiary};
  margin: 45px ${({ theme }) => theme.spacing.regular}
    ${({ theme }) => theme.spacing.less};
  display: block;
  text-align: center;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing.more} 0;
`

const Footer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.double};
`
