import { FC, PropsWithChildren, useEffect, useRef } from "react"
import appConfig from "../app-config"
import AppStart from "@hornet-web-react/core/components/Utils/AppStart"
import { UseAppStartEmitter } from "@hornet-web-react/core/hooks/use-app-start"
import { useSessionUser } from "@hornet-web-react/core/contexts/session"
import { getQuickiesEntitlementStateFromSessionEntitlements } from "../utils/get-quickies-entitlement-state-from-session-entitlements"
import { createEntitlement } from "@hornet-web-react/core/models/entitlement-model"
import { QuickiesEntitlementState } from "../models/types"
import { useRouter } from "next/router"
import EventTrackerService from "@hornet-web-react/core/services/EventTrackerService"
import { useCoreService } from "@hornet-web-react/core/contexts/services"
import { CORE_TYPES } from "@hornet-web-react/core/services/types"
import TrackEvent from "@hornet-web-react/core/models/track-event"
import { useAppContainerContextApi } from "../contexts/app-container-context"
import { useQuickiesSession } from "../hooks/use-quickies-session"
import { AdhocCampaignId } from "@hornet-web-react/core/models/adhoc-campaign.model"
import NiceModal from "@ebay/nice-modal-react"
import AdhocCampaignModal from "./Modals/AdhocCampaignModal"
import { useQuickiesRealtimeOpenScreen } from "../hooks/use-quickies-realtime-open-screen"

type QuickiesAppStartProps = PropsWithChildren

NiceModal.register("AdhocCampaignModal", AdhocCampaignModal)

const QuickiesAppStart: FC<QuickiesAppStartProps> = ({ children }) => {
  const eventTrackerService = useCoreService<EventTrackerService>(
    CORE_TYPES.EventTrackerService
  )
  const { enableSafeBottomPadding } = useAppContainerContextApi()
  const {
    subscribe: subscribeToOpenScreen,
    unsubscribe: unsubscribeFromOpenScreen,
  } = useQuickiesRealtimeOpenScreen(maybeOpenAdhocCampaign)
  const { isAuthenticated, currentUser } = useSessionUser()
  const router = useRouter()
  const { data: fullSession } = useQuickiesSession()
  const adhocCampaignIdsShown = useRef<AdhocCampaignId[]>([])

  function maybeOpenAdhocCampaign(deeplinksList: string[]) {
    // handle only Adhoc campaign at this point
    const adhocCampaignId: AdhocCampaignId | null = deeplinksList.reduce(
      (adhocCampaignId, deeplink) => {
        // first found wins
        if (adhocCampaignId) {
          return adhocCampaignId
        }

        const matches = deeplink.match(/\/adhoc_campaigns\/([a-zA-Z0-9]+)$/)
        if (matches) {
          const id = AdhocCampaignId.safeParse(matches[1])
          return id.success ? id.data : null
        }

        return null
      },
      null as AdhocCampaignId | null
    )

    if (
      adhocCampaignId &&
      !adhocCampaignIdsShown.current.includes(adhocCampaignId)
    ) {
      adhocCampaignIdsShown.current.push(adhocCampaignId)

      // NiceModal.show("AdhocCampaignModal", {
      //   adhocCampaignId: adhocCampaignId,
      // })
      return
    }
  }

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    subscribeToOpenScreen()

    return () => {
      unsubscribeFromOpenScreen()
    }
  }, [isAuthenticated, subscribeToOpenScreen, unsubscribeFromOpenScreen])

  useEffect(() => {
    const [entitlementState] =
      getQuickiesEntitlementStateFromSessionEntitlements(
        (currentUser?.entitlements || []).map(createEntitlement)
      )

    const hasPremium =
      entitlementState === QuickiesEntitlementState.enum.purchased

    const changeAppStartDoneChangedHandler = (appStartDone: boolean) => {
      if (appStartDone) {
        void eventTrackerService.reportPageLoadedOnce(
          router.pathname,
          hasPremium,
          [{ key: "hx", string_value: entitlementState }]
        )
      }
    }

    const handleRouteComplete = (routerPathname: string) => {
      void eventTrackerService.report(
        TrackEvent.pageViewed(routerPathname, hasPremium).addParams([
          { key: "hx", string_value: entitlementState },
        ])
      )

      // fixes the situation when user opens the keyboard from chat input (on
      // inbox conversation screen) - which disables the safe bottom padding - and
      // then leaves *via swiping to the left* (which doesn't trigger the keyboard
      // blur event captured by `handleChatInputOnBlur`)
      enableSafeBottomPadding()
    }

    UseAppStartEmitter.on(
      "appStartDoneChanged",
      changeAppStartDoneChangedHandler
    )

    router.events.on("routeChangeComplete", handleRouteComplete)

    return () => {
      UseAppStartEmitter.off(
        "appStartDoneChanged",
        changeAppStartDoneChangedHandler
      )
      router.events.off("routeChangeComplete", handleRouteComplete)
    }
  }, [
    currentUser,
    enableSafeBottomPadding,
    eventTrackerService,
    router.events,
    router.pathname,
  ])

  useEffect(() => {
    if (fullSession?.onOpenDeeplinks.length) {
      maybeOpenAdhocCampaign(fullSession.onOpenDeeplinks)
    }
  }, [fullSession?.onOpenDeeplinks])

  return (
    <AppStart appConfig={appConfig} hasPushNotifications={true}>
      {children}
    </AppStart>
  )
}

export default QuickiesAppStart
